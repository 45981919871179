import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import {
  useSecurityStore,
} from '@/modules/security/store';

/**
 * An array containing the backlogged requests that are waiting on a new
 * authentication token.
 */
let pendingRequests: (() => void)[] = [];

/**
 * Flag to indicate whether a new authentication token is being fetched. If this
 * flag is true, any further requests should be added to the request backlog.
 */
let isRefreshing = false;

/**
 * Invoked when an Apollo request encounters an error from the back end. This
 * link handles the "unauthorized" error by attempting to refresh the user's
 * authentication token.
 */
export default onError(({ graphQLErrors, operation, forward }) => {
  const securityStore = useSecurityStore();

  if (graphQLErrors) {
    for (const { message } of graphQLErrors) {
      // TODO: Check for the error type instead of the error message.
      if (message === 'The user is not authenticated for this request') {
        // If the `isRefreshing` flag is set, we have to put this request on the
        // backlog and not request a new authentication token.
        if (isRefreshing) {
          return fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve(null));
            }),
          ).flatMap(() => forward(operation));
        }

        isRefreshing = true;

        return fromPromise(
          securityStore
            .refreshTokens()
            .then((authenticationToken: string | undefined): void => {
              // If the `authenticationToken` is "undefined" at this point, that
              // means that we weren't able to acquire a new authentication
              // token, so we should log the user out.
              if (authenticationToken === undefined) {
                pendingRequests = [];

                securityStore.logout();

                return;
              }

              // Re-execute the requests in the backlog as we now have a valid
              // authentication token again.
              pendingRequests.map((callback) => callback());
              pendingRequests = [];
            })
            .catch((error): void => {
              console.error(error);

              // Clear the request backlog and log the user out, as we were
              // unable to acquire a new authentication token.
              pendingRequests = [];

              securityStore.logout();
            })
            .finally((): void => {
              isRefreshing = false;
            }),
        ).flatMap(() => forward(operation));
      }
    }
  }
});
