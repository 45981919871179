enum Token {
  L_PAREN,
  R_PAREN,
  AND,
  OR,
  NEGATE,
  NUMBER,
  FUNCTION,
  OPERATOR,
};

const operators = [
  '==',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
];

const isNumber = (input: string): boolean => {
  return !isNaN(parseFloat(input)) && isFinite(parseFloat(input));
};

const isString = (input: string): boolean => {
  return /^[a-z]+$/i.test(input);
};

const isOperator = (input: string): boolean => {
  return operators.find((operator) => operator === input) !== undefined;
};

export const tokenize = (input: string): any[] => {
  const tokens: { type: Token, value?: any }[] = [];

  let token = '';

  for (let i = 0; i < input.length; ++i) {
    token += input[i].trim();

    const peek = input[i + 1];

    if (isNumber(token) && !isNumber(peek)) {
      tokens.push({
        type: Token.NUMBER,
        value: parseFloat(token),
      });

      token = '';
    }

    if (isString(token) && (!isString(peek) || !peek)) {
      tokens.push({
        type: Token.FUNCTION,
        value: token,
      });

      token = '';
    }

    if (isOperator(token) && !isOperator(peek)) {
      tokens.push({
        type: Token.OPERATOR,
        value: token,
      });

      token = '';
    }

    if (token === '!') {
      tokens.push({
        type: Token.NEGATE,
      });

      token = '';
    }

    if (token === '&&') {
      tokens.push({
        type: Token.AND,
      });

      token = '';
    }

    if (token === '||') {
      tokens.push({
        type: Token.OR,
      });

      token = '';
    }

    if (token === '(') {
      tokens.push({
        type: Token.L_PAREN,
      });

      token = '';
    }

    if (token === ')') {
      tokens.push({
        type: Token.R_PAREN,
      });

      token = '';
    }
  }

  return tokens;
};

const validators: { [ key: string ]: any } = {
  blank: (input: string): boolean => {
    return input.length === 0;
  },

  length: (input: string, comparator: string, operand: number): boolean => {
    switch (comparator) {
    case '>':
      return input.length > operand;

    case '==':
      return input.length === operand;
    }

    return false;
  },

  email: (input: string): boolean => {
    return input.length === 4;
  },
};

export class Parser {
  private tokens: any[] = [];

  constructor(expression: string) {
    this.tokens = tokenize(expression);
  }

  public evaluate(input: string): boolean {
    return this.evaluateGroup(input, this.tokens);
  }

  private evaluateGroup(input: string, tokens: any[]): boolean {
    let lastResult: boolean = false;
    let result: boolean = false;

    let isNegated = false;
    let comparator: any = undefined;

    for (let i = 0; i < tokens.length; ++i) {
      const token = tokens[i];

      switch (token.type) {
      case Token.L_PAREN:
        // Find the _last_ index where R_PAREN is.
        let lastIndex = -1;

        tokens.forEach((token, index) => {
          if (token.type === Token.R_PAREN) {
            lastIndex = index;
          }
        });

        if (lastIndex === -1) {
          console.error('Syntax error');

          return false;
        }

        result = this.evaluateGroup(input, tokens.splice(i + 1, (lastIndex - i) - 1));

        if (isNegated) {
          isNegated = false;
          result = !result;
        }
        break;

      case Token.NEGATE:
        isNegated = true;
        break;

      case Token.FUNCTION:
        lastResult = result;

        // TODO: Make this dynamic.
        if (token.value === 'length') {
          result = validators[token.value](input, tokens[i + 1].value, tokens[i + 2].value);

          i += 2;
        } else {
          result = validators[token.value](input);
        }

        if (isNegated) {
          isNegated = false;
          result = !result;
        }

        if (comparator) {
          switch (comparator) {
          case Token.AND:
            result = lastResult && result;
            break;

          case Token.OR:
            result = lastResult || result;
            break;
          }
        }
        break;

      case Token.AND:
      case Token.OR:
        comparator = token.type;
        break;
      }
    }

    return result;
  }
};
