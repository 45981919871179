import {
  ApolloLink,
} from '@apollo/client/core';


import errorLink from './errorLink';
import securityLink from './securityLink';
import httpLink from './httpLink';


export default ApolloLink.from([
  errorLink,
  securityLink,
  httpLink,
]);
