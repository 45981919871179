
  import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { DateTime } from 'luxon';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

  export default defineComponent({
    name: 'v-find-parent',

    props: {
      exclude: {
        required: false,
        type: String,
      },

      excludeGender: {
        required: false,
        type: String,
      },
    },

    emits: [ 'found', 'cancel' ],

    setup(props, { emit }) {
      const searchQuery = ref('');

      const flags = reactive({
        hasSearched: false,
      });

      const searchQueryField = ref<HTMLElement | null>(null);

      onMounted(() => {
        if (searchQueryField.value) {
          searchQueryField.value.focus();
        }
      });

      // Load the object's data from the back end.
      const searchQueryDisabled = ref(true);

      const { result, loading } = useQuery(gql`
        query SearchPerson(
          $query: String!
        ) {
          searchPersons(query: $query) {
            maidenName
            firstNames
            nickname
            lastName
            gender
            bornAt
            id
          }
        }
      `, () => {
        return {
          query: searchQuery.value,
        }
      }, () => {
        return {
          fetchPolicy: 'no-cache',
          enabled: !searchQueryDisabled.value,
        }
      });

      const searchResults = useResult(result, null, (data) => {
        searchQueryDisabled.value = true;

        let results = data.searchPersons as any[];

        if (props.exclude) {
          results = results.filter((result) => {
            return result.id !== props.exclude;
          });
        }

        if (props.excludeGender) {
          results = results.filter((result) => {
            return result.gender !== props.excludeGender;
          });
        }

        return results;
      });

      const showDate = (date: string): string => {
        return DateTime.fromISO(date).setLocale('nl').toLocaleString(DateTime.DATE_FULL);
      };

      const onSearchSubmit = (): void => {
        if (searchQuery.value.length === 0) {
          return;
        }

        flags.hasSearched = true;

        searchQueryDisabled.value = false;
      };

      const onPersonClick = (id: string): void => {
        emit('found', id);
      };

      const onCancelClick = (): void => {
        emit('cancel');
      };

      const modal = ref<HTMLElement | null>(null);

      onMounted(() => {
        if (modal.value) {
          disableBodyScroll(modal.value);
        }
      });

      onBeforeUnmount(() => {
        if (modal.value) {
          enableBodyScroll(modal.value);
        }
      });

      return {
        onSearchSubmit,

        flags,

        searchResults,
        searchQuery,
        showDate,
        loading,

        onPersonClick,
        onCancelClick,

        searchQueryField,
        modal,
      };
    },
  });
