
  import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { DateTime } from 'luxon';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useSecurityStore } from '@/modules/security/store';

import Constants from '@/constants';

  export default defineComponent({
    name: 'v-add-person-dialog',

    emits: [ 'found', 'cancel' ],

    props: {
      exclude: {
        required: false,
        type: String,
      },

      excludeGender: {
        required: false,
        type: String,
      },
    },

    setup(props, { emit }) {
      const fields = reactive({
        maidenName: '',
        firstNames: '',
        lastName: '',
        nickname: '',
        gender: '',
        bornAt: '',
        bornIn: '',
        diedAt: '',
        diedIn: '',
      });

      const searchQuery = ref('');

      const flags = reactive({
        addNewPerson: false,
        hasSearched: false,
      });

      const { onDone: onCreateDone, mutate: create } = useMutation(gql`
        mutation CreatePerson(
          $firstNames: String
          $nickname: String
          $lastName: String
          $maidenName: String
          $gender: Gender!
          $bornAt: DateTime
          $bornIn: String
          $diedAt: DateTime
          $diedIn: String
        ) {
          createPerson(
            input: {
              firstNames: $firstNames
              nickname: $nickname
              lastName: $lastName
              maidenName: $maidenName
              gender: $gender
              bornAt: $bornAt
              bornIn: $bornIn
              diedAt: $diedAt
              diedIn: $diedIn
            }
          ) {
            person {
              id
              picture
              father {
                id
                firstNames
                nickname
                lastName
                maidenName
              }
              mother {
                id
                firstNames
                nickname
                lastName
                maidenName
              }
              gender
              firstNames
              nickname
              lastName
              maidenName
              bornAt
              bornIn
              diedAt
              diedIn
              remarks
              photos {
                id
                files(reference: "front") {
                  id
                  width
                  height
                  annotations {
                    id
                    data {
                      ... on PersonAnnotation {
                        person {
                          id
                        }
                        radius
                        x
                        y
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);

      onCreateDone((result) => {
        emit('found', result.data.createPerson.person);
      });

      const searchQueryField = ref<HTMLElement | null>(null);

      onMounted(() => {
        if (searchQueryField.value) {
          searchQueryField.value.focus();
        }
      });

      // Load the object's data from the back end.
      const searchQueryDisabled = ref(true);

      const { result, loading } = useQuery(gql`
        query SearchPerson(
          $query: String!
        ) {
          searchPersons(query: $query) {
            id
            picture
            father {
              id
              firstNames
              nickname
              lastName
              maidenName
            }
            mother {
              id
              firstNames
              nickname
              lastName
              maidenName
            }
            gender
            firstNames
            nickname
            lastName
            maidenName
            bornAt
            bornIn
            diedAt
            diedIn
            remarks
            photos {
              id
              files(reference: "front") {
                id
                width
                height
                annotations {
                  id
                  data {
                    ... on PersonAnnotation {
                      person {
                        id
                      }
                      radius
                      x
                      y
                    }
                  }
                }
              }
            }
          }
        }
      `, () => {
        return {
          query: searchQuery.value,
        }
      }, () => {
        return {
          fetchPolicy: 'no-cache',
          enabled: !searchQueryDisabled.value,
        }
      });

      const searchResults = useResult(result, null, (data) => {
        searchQueryDisabled.value = true;

        let results = data.searchPersons as any[];

        if (props.exclude) {
          results = results.filter((result) => {
            return result.id !== props.exclude;
          });
        }

        if (props.excludeGender) {
          results = results.filter((result) => {
            return result.gender !== props.excludeGender;
          });
        }

        return results;
      });

      const formatDate = (date: string): string => {
        if (date.length === 0) {
          return '';
        }

        const parts = date.split('-');

        return `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00+00:00`;
      };

      const showDate = (date: string): string => {
        return DateTime.fromISO(date).setLocale('nl').toLocaleString(DateTime.DATE_FULL);
      };

      const onSubmit = (): void => {
        create({
          maidenName: fields.gender === 'MALE' ? '' : fields.maidenName,
          firstNames: fields.firstNames,
          lastName: fields.lastName,
          nickname: fields.nickname,
          gender: fields.gender,
          bornAt: formatDate(fields.bornAt),
          bornIn: fields.bornIn,
          diedAt: formatDate(fields.diedAt),
          diedIn: fields.diedIn,
        });
      };

      const onSearchSubmit = (): void => {
        if (searchQuery.value.length === 0) {
          return;
        }

        flags.hasSearched = true;

        searchQueryDisabled.value = false;
      };

      const onPersonClick = (person: string): void => {
        emit('found', person);
      };

      const onCancelClick = (): void => {
        emit('cancel');
      };

      const modal = ref<HTMLElement | null>(null);

      onMounted(() => {
        if (modal.value) {
          disableBodyScroll(modal.value);
        }
      });

      onBeforeUnmount(() => {
        if (modal.value) {
          enableBodyScroll(modal.value);
        }
      });

      const securityStore = useSecurityStore();

      const getPictureForPerson = (person: any): string => {
        return `${Constants.CDN_URL}/person/${person.id}/${securityStore.authenticationToken}?${person.picture}`;
      };

      return {
        onSearchSubmit,
        onSubmit,

        flags,
        fields,

        searchResults,
        searchQuery,
        showDate,
        loading,

        onPersonClick,
        onCancelClick,

        searchQueryField,
        modal,

        getPictureForPerson,
      };
    },
  });
