import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Constants from '@/constants';
import { useSecurityStore } from '@/modules/security/store';

/**
 * The available routes in the application.
 */
 const availableRoutes: RouteRecordRaw[] = [
  {
    component: () => {
      return import(
        /* webpackChunkName: "view.home" */
        '@/views/Home.vue'
      );
    },
    name: 'home',
    path: '/',
    meta: {
      requiresAuth: true,
    },
  },

  {
    component: () => {
      return import(
        /* webpackChunkName: "view.collection" */
        '@/views/Collection.vue'
      );
    },
    name: 'collection',
    path: '/collection/:collectionId',
    meta: {
      requiresAuth: true,
    },
  },

  {
    component: () => {
      return import(
        /* webpackChunkName: "view.object" */
        '@/views/Object.vue'
      );
    },
    name: 'object',
    path: '/object/:objectId',
    meta: {
      requiresAuth: true,
    },
  },

  {
    component: () => {
      return import(
        /* webpackChunkName: "view.persons" */
        '@/views/Persons.vue'
      );
    },
    name: 'persons',
    path: '/persons',
    meta: {
      requiresAuth: true,
    },
  },

  {
    component: () => {
      return import(
        /* webpackChunkName: "view.persons" */
        '@/views/Persons.vue'
      );
    },
    name: 'persons.single',
    path: '/persons/:personId',
    meta: {
      requiresAuth: true,
    },
  },
 ];

 // Register the routes in the modules.
 const requireRoutes = require.context(
   '@/modules',
   true,
   /routes\.ts$/,
 );

 requireRoutes.keys().forEach((fileName) => {
   const moduleRoutes: RouteRecordRaw[] = requireRoutes(fileName).default;

   availableRoutes.push(...moduleRoutes);
 });

const router = createRouter({
  history: createWebHistory(Constants.APP_URL),
  routes: availableRoutes,
});

router.beforeEach((to) => {
  const securityStore = useSecurityStore();

  if (to.meta.requiresAuth && !securityStore.authenticationToken) {
    return {
      name: 'security.login',
    };
  }

  if (to.meta.guestOnly && securityStore.authenticationToken) {
    return {
      name: 'home',
    };
  }
});

export default router;
