
  import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useSecurityStore } from './modules/security/store';

  export default defineComponent({
    setup() {
      const securityStore = useSecurityStore();
      const router = useRouter();

      const { result, refetch } = useQuery(gql`
        query GetUser {
          currentUser {
            markedDone
          }
        }
      `, null, {
        fetchPolicy: 'no-cache',
      });

      router.beforeEach(() => {
        if (securityStore.isAuthenticated) {
          refetch();

          watch(result, (value) => {
            if (
              value.currentUser.markedDone.length > 0 &&
              (window.localStorage.getItem('objects_done') ?? '[]') !== JSON.stringify(value.currentUser.markedDone)
            ) {
              window.localStorage.setItem('objects_done', JSON.stringify(value.currentUser.markedDone));
            }
          });
        }
      });

      const isAuthenticated = computed(() => {
        return securityStore.isAuthenticated;
      });

      // This watches the `auth/isAuthenticated` property in the Vuex store, and
      // redirects the user to the login page if this property is set to false
      // (e.g. when the user logs out manually, or if we are unable to acquire a
      // new authentication token).
      watch(isAuthenticated, (value, oldValue) => {
        if (oldValue && !value) {
          router.push({
            name: 'security.login',
          });
        }
      });
    },
  });
