
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

  export default defineComponent({
    name: 'v-confirm-dialog',

    emits: [ 'cancel' ],

    setup(_, { emit }) {
      const modal = ref<HTMLElement | null>(null);

      const onCancelClick = (): void => {
        emit('cancel');
      };

      onMounted(() => {
        if (modal.value) {
          disableBodyScroll(modal.value);
        }
      });

      onBeforeUnmount(() => {
        if (modal.value) {
          enableBodyScroll(modal.value);
        }
      });
      return {
        onCancelClick,
        modal,
      };
    },
  });
