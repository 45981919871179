import {
  defineStore,
} from 'pinia';


import Constants from '@/constants';
import RefreshAuthenticationToken, {
  RefreshAuthenticationTokenData,
} from './graphql/mutations/RefreshAuthenticationToken';


const useSecurityStore = defineStore('security', {
  state: () => {
    return {
      authenticationToken: window.localStorage.getItem('authenticationToken') ?? undefined,
      isAuthenticated: window.localStorage.getItem('is_authenticated') === 'true',
      refreshToken: window.localStorage.getItem('refreshToken') ?? undefined,
      name: window.localStorage.getItem('name') ?? undefined,
    };
  },

  actions: {
    setTokens(authenticationToken: string, refreshToken: string): void {
      this.$state.authenticationToken = authenticationToken;
      this.$state.isAuthenticated = true;
      this.$state.refreshToken = refreshToken;

      window.localStorage.setItem('authenticationToken', authenticationToken);
      window.localStorage.setItem('is_authenticated', 'true');
      window.localStorage.setItem('refreshToken', refreshToken);
    },

    setName(name: string): void {
      this.$state.name = name;

      window.localStorage.setItem('name', name);
    },

    async refreshTokens(): Promise<string | undefined> {
      // We cannot use Apollo's `useMutation' here, because that would go
      // directly to the `errorLink' to refresh the authentication token, as
      // Apollo has no way of marking requests that can be done without a valid
      // authentication token.
      return fetch(`${Constants.BACKEND_URL}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          variables: {
            refreshToken: this.$state.refreshToken,
          },
          query: RefreshAuthenticationToken.loc?.source.body,
        }),
      })
        .then(async (value) => {
          try {
            const result = await value.json();
            const data = result.data?.refreshAuthenticationToken as RefreshAuthenticationTokenData;

            if (!data) {
              return undefined;
            }

            this.setTokens(data.authenticationToken, data.refreshToken);

            return data.authenticationToken;
          } catch (error) {
            console.error(error);

            return undefined;
          }
        })
        .catch((error) => {
          console.error(error);

          return undefined;
        });
    },

    logout(): void {
      this.$state.authenticationToken = undefined;
      this.$state.isAuthenticated = false;
      this.$state.refreshToken = undefined;
      this.$state.name = undefined;

      window.localStorage.removeItem('authenticationToken');
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('name');

      window.localStorage.setItem('is_authenticated', 'false');
    },
  },
});

export { useSecurityStore };

