import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client/core';


import links from './links';


export default new ApolloClient({
  cache: new InMemoryCache(),
  link: links,
});
