
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    inject,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
    toRefs,
  } from 'vue';


  import { Parser } from '@/utils/validator/tokenizer';


  type ValidationProps = {
    constraints: string;

    customRules: { [ name: string ]: any };
  };


  export default defineComponent({
    inheritAttrs: false,
    name: 'v-form-control',

    props: {
      name: {
        required: false,
        type: String,
      },

      validator: {
        required: false,
        type: String,
      },

      modelValue: {
        required: false,
        type: String,
      },
    },

    emits: [
      'update:modelValue',
    ],

    setup(props, { emit }) {
      const { validator: constraints, modelValue } = toRefs(props);

      const field: any = inject('formField');
      const form: any = inject('form');
      const uid = getCurrentInstance()?.uid;

      const value = form.getModelValue(props.name) ?? modelValue;

      const flags = reactive({
        hasBlurred: false,
      });

      const validator = computed(() => {
        if (!constraints.value) {
          return;
        }

        return new Parser(constraints.value);
      });

      const validate = (value?: string): boolean => {
        if (!validator.value) {
          return true;
        }

        const state = validator.value.evaluate(value ?? props.modelValue ?? '');

        field.setValidationState(state);

        return state;
      };

      const onInput = (event: any): void => {
        form.setModelValue(props.name, event.target.value);

        emit('update:modelValue', event.target.value);

        if (!flags.hasBlurred) {
          return;
        }

        // The `props.modelValue' cannot be used yet, as its update is not yet
        // propagated.
        validate(event.target.value);
      };

      const onBlur = (): void => {
        flags.hasBlurred = true;

        if (!validator) {
          return;
        }

        validate();
      };

      console.log(form.getModelValue(props.name));

      onMounted(() => {
        form.bind(uid, validate);

        // const modelValue = form.getModelValue(props.name);

        // if (modelValue) {
        //   value = modelValue;
        // }
      });

      onBeforeUnmount(() => {
        form.unbind(uid);
      });

      return {
        onInput,
        onBlur,

        value,
      };
    },
  });
