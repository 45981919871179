import {
  DefaultApolloClient,
} from '@vue/apollo-composable';

import {
  createApp,
} from 'vue';


import GraphQLClient from '@/graphql/client';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';


const app = createApp(App);

app.provide(DefaultApolloClient, GraphQLClient);

app.use(router);
app.use(store);

// Register the global components and layout components.
const requireComponent = require.context(
  './',
  true,
  /\.\/(components|layouts)\/.*?\.vue$/,
);

requireComponent.keys().forEach((fileName) => {
  let component = requireComponent(fileName);
  component = component.default || component;

  app.component(component.name, component);
});

app.mount('#app');
