import { setContext } from '@apollo/client/link/context';

import {
  useSecurityStore,
} from '@/modules/security/store';

export default setContext((_, { headers }) => {
  const securityStore = useSecurityStore();
  const newHeaders = { ...headers };

  const authenticationToken = securityStore.authenticationToken;

  if (authenticationToken) {
    newHeaders['Authorization'] = `Bearer ${authenticationToken}`;
  }

  return {
    headers: newHeaders,
  };
});
