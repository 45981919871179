
  import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { DateTime } from 'luxon';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import Constants from '@/constants';
import { useSecurityStore } from '@/modules/security/store';

  export default defineComponent({
    name: 'v-set-person-picture',

    props: {
      person: {
        required: true,
        type: Object,
      },
    },

    emits: [ 'done', 'cancel' ],

    setup(props, { emit }) {
      const annotations = computed(() => {
        const result: any = [];

        props.person.photos.forEach((photo: any) => {
          const data = {
            data: photo.files[0].annotations.find((annotation: any) => {
              return annotation.data.person.id === props.person.id;
            }),
            file: photo.files[0],
          };

          if (data.data.id !== props.person.picture) {
            result.push(data);
          }
        });

        return result;
      });

      const { onDone: onDone, mutate: set } = useMutation(gql`
        mutation SetPersonPicture(
          $id: ID!
          $annotationId: ID!
        ) {
          setPersonPicture(
            id: $id
            input: {
              annotationId: $annotationId
            }
          ) {
            person {
              id
            }
          }
        }
      `);

      onDone(() => {
        emit('done');
      });

      const isLoading = ref(false);
      const annotationId = ref<string | undefined>(undefined);

      const modal = ref<HTMLElement | null>(null);

      const onCancelClick = (): void => {
        emit('cancel');
      };

      onMounted(() => {
        if (modal.value) {
          disableBodyScroll(modal.value);
        }
      });

      onBeforeUnmount(() => {
        if (modal.value) {
          enableBodyScroll(modal.value);
        }
      });

      const securityStore = useSecurityStore();

      const getUrlForFile = (file: any): string => {
        return `${Constants.CDN_URL}/${file.id}/thumbnail/${securityStore.authenticationToken}`;
      };

      const onPictureClick = (id: string): void => {
        if (isLoading.value) {
          return;
        }

        annotationId.value = id;
        isLoading.value = true;

        set({
          id: props.person.id,
          annotationId: id,
        });
      };

      return {
        getUrlForFile,

        onCancelClick,
        onPictureClick,

        isLoading,
        annotations,
        annotationId,
        modal,
      };
    },

    methods: {
      getAnnotationParameters: function (file: any, annotation: any): any {
        const ratio = 88 / (annotation.data.radius * 2);

        return {
          height: file.height * ratio,
          width: file.width * ratio,
          x: (annotation.data.x - annotation.data.radius) * ratio * -1,
          y: (annotation.data.y - annotation.data.radius) * ratio * -1,
        };
      },
    },
  });
