import {
  createHttpLink,
} from '@apollo/client/core';


import Constants from '@/constants';


export default createHttpLink({
  uri: Constants.BACKEND_URL,
});
