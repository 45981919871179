
  import {
    defineComponent,
  } from 'vue';

  export default defineComponent({
    inheritAttrs: false,
    name: 'v-form-label',

    setup() {
      return {
      };
    },
  });
