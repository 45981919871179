import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-273f0749")
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = {
  class: "modal__body",
  ref: "modal"
}
const _hoisted_3 = { class: "modal__actions" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: ".modals" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "modal__close",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCancelClick && _ctx.onCancelClick(...args)), ["prevent"]))
        }, "Annuleren"),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ])
      ], 512)
    ])
  ]))
}