/**
 * The available constant values.
 */
 export default {
  /**
   * The name of the environment that the application is running in.
   */
  ENVIRONMENT: process.env.NODE_ENV as Environment,

  /**
   * The full URL of the back end.
   */
  BACKEND_URL: process.env.VUE_APP_BACKEND_URL as string,

  /**
   * The full URL of the CDN.
   */
  CDN_URL: process.env.VUE_APP_CDN_URL as string,

  /**
   * The full URL of the application.
   */
  APP_URL: process.env.BASE_URL as string,
};

/**
 * The available environments in which the application can be run.
 */
export enum Environment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
};

