
  import {
    defineComponent,
    provide,
    ref,
  } from 'vue';

  export default defineComponent({
    inheritAttrs: false,
    name: 'v-form-field',

    setup() {
      const errorMessage = ref('');

      const setValidationState = (state: boolean): void => {
        errorMessage.value = state ? 'good' : 'has error';
      };

      provide('formField', {
        setValidationState,
      });

      return {
        errorMessage,
      };
    },
  });
