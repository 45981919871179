import {
  RouteRecordRaw,
} from 'vue-router';

export default [
  {
    component: () => {
      return import(
        /* webpackChunkName: "view.security.login" */
        '@/modules/security/views/Login.vue'
      );
    },
    name: 'security.login',
    path: '/login',
    meta: {
      requiresAuth: false,
      guestOnly: true,
    },
  },
] as RouteRecordRaw[];
