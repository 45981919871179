
  import { defineComponent } from 'vue';

  import { useSecurityStore } from '@/modules/security/store';

  export default defineComponent({
    name: 'v-layout-default',

    setup() {
      const securityStore = useSecurityStore();

      return {
        name: securityStore.name,
      };
    },
  });
