import gql from 'graphql-tag';


export default gql`
  mutation RefreshAuthenticationTokenData(
    $refreshToken: String!
  ) {
    refreshAuthenticationToken(
      input: {
        refreshToken: $refreshToken
      }
    ) {
      authenticationToken
      refreshToken
    }
  }
`;

export type RefreshAuthenticationTokenData = {
  authenticationToken: string;
  refreshToken: string;
};
