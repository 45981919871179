
  import { defineComponent, provide, Ref, ref, toRefs } from 'vue';

  export default defineComponent({
    inheritAttrs: false,
    name: 'v-form',

    props: {
      modelValue: {
        required: false,
        type: Object,
      },
    },

    setup(props, { emit }) {
      const { modelValue } = toRefs(props);

      const controls: any = {};

      const bind = (uid: any, callback: any): void => {
        if (Object.values(controls).indexOf(uid) !== -1) {
          return;
        }

        controls[uid] = callback;
      };

      const unbind = (uid: any): void => {
        delete controls[uid];
      };

      const getModelValue = (name: string): string | undefined => {
        if (!modelValue.value || !modelValue.value[name]) {
          return undefined;
        }

        return modelValue.value[name];
      };

      const setModelValue = (name: string, value: string): void => {
        console.log(name, value);

        emit(`update:${name}`, value);
      };

      provide('form', { bind, unbind, getModelValue, setModelValue });

      const onSubmit = (): void => {
        Object.values(controls).forEach((validator: any) => {
          console.log('submit', validator());
        });
      };

      return {
        onSubmit,
      };
    },
  });
