<template>
  <teleport to=".outside">
    <div
        class="enlarge"

        @wheel="onMouseWheel"
    >
        <div class="enlarge__background" :style="styling.background" />

        <button class="enlarge__close" :style="styling.close" @click="onCloseClicked">Sluiten</button>

        <div class="enlarge__object-wrapper" :style="styling.object.wrapper">
            <svg
                class="enlarge__object"

                :style="styling.object.inner"

                @mousemove="onMouseMove"
            >
                <defs>
                    <!-- Define the image of the main photo -->
                    <image
                        :height="scale(file.height)"
                        :width="scale(file.width)"
                        :href="getUrlForFile(file)"
                        :id="`photo-${file.id}-enlarged`"
                    />

                    <image
                        :height="zoomScale(file.height)"
                        :width="zoomScale(file.width)"
                        :href="getUrlForFile(file)"
                        :id="`photo-${file.id}-zoomed`"
                    />

                    <filter x="-6.4%" y="-6.4%" width="112.7%" height="112.7%" filterUnits="objectBoundingBox" id="filter-3">
                        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                        <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>

                    <mask id="zoom-overlay">
                        <rect width="100%" height="100%" fill="#000" />

                        <circle
                            fill="#fff"

                            :cx="scale(zoom.x)"
                            :cy="scale(zoom.y)"
                            :r="zoom.radius"
                        />
                    </mask>
                </defs>

                <!-- Add the photo -->
                <use :href="`#photo-${file.id}-enlarged`"/>

                <g mask="url('#zoom-overlay')">
                    <use
                        :transform="'translate(' + zoom.offsetX + ', ' + zoom.offsetY + ')'"
                        :href="`#photo-${file.id}-zoomed`"
                    />
                </g>

                <!-- <transition name="fade"> -->
                    <g v-show="zoom.scale > 1">
                        <!-- <circle
                            filter="url(#filter-3)"

                            :cx="scale(zoom.x)"
                            :cy="scale(zoom.y)"
                            :r="zoom.radius"
                        /> -->

                        <circle
                            stroke-width="2"
                            stroke="#fff"
                            fill="none"

                            :cx="scale(zoom.x)"
                            :cy="scale(zoom.y)"
                            :r="zoom.radius"
                        />
                    </g>
                <!-- </transition> -->
            </svg>
        </div>

        <!-- <div
            class="scale is-rotation"
            ref="scale-rotation"

            :style="styling.scale.rotation.wrapper"
        >
            <i
                class="scale__indicator"

                :style="styling.scale.rotation.indicator"
            />

            <div
                class="scale__inner"
                ref="scale-rotation-inner"

                :style="styling.scale.rotation.inner"
            >
                <template v-for="angle in 180">
                    <span class="scale__tick is-major" :key="`rotate-${angle}-up`" v-if="(angle - 1) % 5 === 0 && angle !== 180">
                        <span class="scale__tick__label">{{ (180 - angle + 1) }}&deg;</span>
                    </span>

                    <span class="scale__tick is-minor" :key="`rotate-${angle}-up`" v-else />
                </template>

                <span class="scale__tick is-major" ref="scale-rotation-zero">
                    <span class="scale__tick__label">0&deg;</span>
                </span>

                <template v-for="angle in 180">
                    <span class="scale__tick is-major" :key="`rotate-${angle}-down`" v-if="angle % 5 === 0">
                        <span class="scale__tick__label">-{{ angle }}&deg;</span>
                    </span>

                    <span class="scale__tick is-minor" :key="`rotate-${angle}-down`" v-else />
                </template>
            </div>
        </div> -->

        <div class="scale is-zoom" ref="scale-zoom" :style="styling.scale.zoom.wrapper">
            <span class="scale__help">ALT + scroll</span>

            <i
                class="scale__indicator"

                :style="styling.scale.zoom.indicator"
            />

            <template v-for="major in 5" :key="`zoom-${major}`">
                <template v-if="major != 1">
                    <span
                        class="scale__tick is-minor"

                        :key="`zoom-${major}.${minor}`"

                        v-for="minor in 4"
                    />
                </template>

                <span class="scale__tick is-major">
                    <span class="scale__tick__label">{{ 6 - major }}x</span>
                </span>
            </template>
        </div>
    </div>
  </teleport>
</template>

<script>
import Constants from '@/constants';
import { useSecurityStore } from '@/modules/security/store';

export default {
  name: 'v-enlarge',

    computed: {
        actualScaleFactor() {
            const [ width, height ] = this.rotatedSize([ this.file.width, this.file.height ], this.rotation.angle);

            if (height * this.scaleFactor > window.innerHeight - (16 * 2)) {
                return (window.innerHeight - (16 * 2)) / height;
            }

            return this.scaleFactor;
        },

        styling() {
            const height = this.file.height;
            const width = this.file.width;

            if (!this.flags.isMounted) {
                return {
                    background: {
                        opacity: '0',
                    },
                    object: {
                        wrapper: {
                            // transform: `translate(${this.photoPosition.left - (window.innerWidth / 2)}px, ${this.photoPosition.top - (window.innerHeight / 2)}px)`,
                            transform: 'translate(-50%, -50%)',
                        },
                        inner: {
                            height: `${this.scale(height)}px`,
                            width: `${this.scale(width)}px`,
                        },
                    },
                    close: {
                        right: '-32px',
                    },
                    scale: {
                        // rotation: {
                        //     wrapper: {
                        //         height: '0',
                        //         left: '-100px',
                        //     },
                        //     indicator: {},
                        //     inner: {},
                        // },
                        zoom: {
                            indicator: {
                                top: '0',
                            },
                            wrapper: {
                                right: '-100px',
                            },
                        },
                    },
                };
            }

            const scaleHeight = this.$refs['scale-zoom'].getBoundingClientRect().height;
            // const distance = -(this.$refs['scale-rotation-zero'].offsetTop - (scaleHeight / 2) + (2 / 2)) - ((16 + 2) * this.rotation.angle);

            return {
                background: {
                    opacity: '1',
                },
                object: {
                    wrapper: {
                        transform: 'translate(-50%, -50%)',
                        height: `${this.scale(height)}px`,
                        width: `${this.scale(width)}px`,
                    },
                    inner: {
                        // transform: `rotateZ(${this.rotation.angle}deg)`,
                        height: `${this.scale(height)}px`,
                        width: `${this.scale(width)}px`,
                    },
                },
                close: {
                    right: '32px',
                },
                scale: {
                    // rotation: {
                    //     indicator: {
                    //         top: `${(scaleHeight - 2) / 2}px`,
                    //     },
                    //     wrapper: {
                    //         height: `${scaleHeight}px`,
                    //         left: '0',
                    //     },
                    //     inner: {
                    //         top: `${distance}px`,
                    //     },
                    // },
                    zoom: {
                        indicator: {
                            top: `${(scaleHeight - 2) / 4 * (5 - this.zoom.scale)}px`,
                        },
                        wrapper: {
                            right: '0',
                        },
                    },
                },
            };
        },
    },

    methods: {
        getUrlForFile(file) {
          const securityStore = useSecurityStore();

          return `${Constants.CDN_URL}/${file.id}/${securityStore.authenticationToken}`;
        },

        /**
         * Returns the scaled size.
         *
         * @return The size multiplied by the scaling factor
         */
        scale(size) {
            return size * this.actualScaleFactor;
        },

        zoomScale(size) {
            return size * this.actualScaleFactor * this.zoom.scale;
        },

        rotatedSize(size, degrees) {
            degrees = degrees % 180;

            if (degrees < 0) {
                degrees = 180 + degrees;
            }

            if (degrees >= 90) {
                size = [ size[1], size[0] ];
                degrees = degrees - 90;
            }

            if (degrees === 0) {
                return [ size[0], size[1] ];
            }

            const radians = degrees * Math.PI / 180;
            const width = (size[0] * Math.cos(radians)) + (size[1] * Math.sin(radians));
            const height = (size[0] * Math.sin(radians)) + (size[1] * Math.cos(radians));

            return [ width, height ];
        },

        onCloseClicked(event) {
            event.preventDefault();

            if (this.flags.isClosing) {
                return;
            }

            this.rotation.angle = 0;
            this.zoom.scale = 1;

            this.$emit('close');
        },

        onMouseMove(event) {
            if (
                event.offsetX < 0 || event.offsetX > this.scale(this.file.width)
                || event.offsetY < 0 || event.offsetY > this.scale(this.file.height)
            ) {
                return;
            }

            const scaleFactor = this.zoom.radius / this.scale(this.zoom.radius);
            const factor = this.file.width / (this.zoomScale(this.file.width) - this.scale(this.file.width));

            this.zoom.x = event.offsetX * scaleFactor;
            this.zoom.y = event.offsetY * scaleFactor;

            this.zoom.offsetX = (event.offsetX * scaleFactor / factor) * -1;
            this.zoom.offsetY = (event.offsetY * scaleFactor / factor) * -1;
        },

        onMouseWheel(event) {
            let affected = false;

            /*if (event.shiftKey) {
                affected = true;

                this.rotation.angle += Math.round(event.deltaY * -0.15);
                this.rotation.angle = Math.min(Math.max(-180, this.rotation.angle), 180);
            } else */if (event.altKey) {
                affected = true;

                this.zoom.scale += event.deltaY * -0.01;
                this.zoom.scale = Math.min(Math.max(1, this.zoom.scale), 5);
            }

            if (affected) {
                event.preventDefault();

                const scaleFactor = this.zoom.radius / this.scale(this.zoom.radius);
                const factor = this.file.width / (this.zoomScale(this.file.width) - this.scale(this.file.width));

                this.zoom.x = event.offsetX * scaleFactor;
                this.zoom.y = event.offsetY * scaleFactor;

                this.zoom.offsetX = (event.offsetX * scaleFactor / factor) * -1;
                this.zoom.offsetY = (event.offsetY * scaleFactor / factor) * -1;
            }
        },
    },

    mounted() {
        if (!this.flags.isMounted) {
            this.flags.isMounted = true;
        }
    },

    emits: [
      'close',
    ],

    props: {
        photoPosition: {
          required: true,
          type: Object,
        },

        scaleFactor: {
            required: true,
            type: Number,
        },

        file: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            closingStyle: {
                wrapper: {
                    transform: 'translate(-50%, -50%)',
                },
            },
            rotation: {
                angle: 0,
            },
            flags: {
                isMounted: false,
                isClosing: false,
            },
            zoom: {
                offsetX: 0,
                offsetY: 0,
                radius: 250,
                scale: 1,
                x: 0,
                y: 0,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.enlarge {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: {
            color: rgba(#000, .9);
        }
        backdrop-filter: blur(5px);
        opacity: 1;
        // transition: opacity .4s ease-in-out;
    }

    &__close {
        position: absolute;
        top: 32px;
        right: -32px;
        display: block;
        width: 32px;
        height: 32px;
        text: {
            indent: -9999px;
        }
        background: {
            repeat: no-repeat;
            image: url("../assets/images/icon_close_white.svg");
            color: transparent;
            size: 32px 32px;
        }
        border: {
            width: 0;
        }
        cursor: pointer;
        // transition: right .4s ease-in-out .4s;

        &:active,
        &:focus {
            outline: none;
        }
    }

    &__object-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform .5s ease-in-out;
    }

    &__object {
        position: absolute;
        top: 0;
        left: 0;
        overflow: unset;
    }
}

.scale {
    position: absolute;
    top: 50%;
    display: flex;
    width: 120px;
    flex: {
        direction: column;
    }
    transform: translateY(-50%);
    // transition: left .4s ease-in-out .4s, right .4s ease-in-out .4s;

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__tick {
        position: relative;
        display: block;
        height: 2px;
        border: {
            radius: 1px;
        }

        &__label {
            position: absolute;
            top: 50%;
            right: calc(100% + 8px);
            display: block;
            font: {
                weight: 600;
                size: 12px;
            }
            color: #fff;
            line-height: 14px;
            transform: translateY(-50%);
            user-select: none;
        }

        &.is-minor {
            width: 24px;
            background: {
                color: rgba(#fff, .5);
            }
        }

        &.is-major {
            width: 32px;
            background: {
                color: #fff;
            }
        }

        &::before {
            position: absolute;
            top: -4px;
            left: -4px;
            display: block;
            width: calc(100% + (4px * 2));
            height: calc(100% + (4px * 2));
            content: "";
        }

        & + & {
            margin: {
                top: 16px;
            }
        }
    }

    &__indicator {
        position: absolute;
        top: 0;
        z-index: 20;
        display: block;
        width: 32px;
        height: 2px;
        background: {
            color: #eb675d;
        }
        border: {
            radius: 1px;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: calc(100% + 6px);
            width: 0;
            height: 0;
            border: {
                top: {
                    color: transparent;
                    style: solid;
                    width: 3px;
                }
                right: {
                    color: #eb675d;
                    style: solid;
                    width: 6px;
                }
                bottom: {
                    color: transparent;
                    style: solid;
                    width: 3px;
                }
                left: {
                    color: transparent;
                    style: solid;
                    width: 0;
                }
            }
            transform: translateY(-50%);
            content: "";
        }
    }

    &__help {
      position: absolute;
      top: calc(100% + 16px);
      right: 32px;
      font-size: 12px;
      color: rgba(#fff, .7);
    }

    &.is-zoom {
        right: 0;
        align-items: flex-end;

        .scale__tick {
            margin: {
                right: 32px;
            }
        }

        .scale__indicator {
            right: 32px;
        }
    }

    &.is-rotation {
        left: 0;
        align-items: flex-start;
        mask-image: linear-gradient(to bottom, rgba(#000, 0) 0px, rgba(#000, 1) (362px / 2) - 130, rgba(#000, 1) (362px / 2) + 130, rgba(#000, 0) 362px);

        .scale__tick {
            margin: {
                left: 32px;
            }
        }

        .scale__tick__label {
            right: unset;
            left: calc(100% + 8px);
        }

        .scale__indicator {
            left: 32px;

            &::before {
                right: calc(100% + 6px);
                left: unset;
                transform: translateY(-50%) rotateY(180deg);
            }
        }
    }
}

// ...
.fade-enter-active,
.fade-leave-active {
    transition: opacity .25s ease-in-out;
    opacity: .75;
}

.fade-leave-to,
.fade-enter {
    opacity: 0;
}

.fade-enter-to {
    opacity: .75;
}
</style>
